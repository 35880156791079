.videoThumb,.videoThumbMain{
    max-width: 100% !important;
    height: 100% !important;
    padding: 0px !important;
    border-radius: 10px;
}

.videoThumb .video-react-poster{
    right:unset !important;
    left: unset !important;
}

.videoThumb .video-react-control-bar-auto-hide{
    display: none !important;
}

#videoThumb{
    object-fit: cover;
    border-radius: 10px;
}

#playVideoMain{
    object-fit: cover;
    padding: 0 200px;
    background-color: black;
}

.hiddenPlay{
    display: none !important;
}